
            @import "@/assets/sass/vars.portal.scss";
          

























































































.currency-modal {
    .fields {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        .equal-element {
            display: flex;
            font-size: 18px;
            font-weight: bold;
        }

        
    }
}
